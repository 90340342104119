/* Global styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f0f2f5;
  color: #333;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(240, 242, 245, 0.8);
}

.login-box {
  /* background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRl5rpob_bx425UMmyvAy2bg3xL1oFvpSxjvI5iuH3ed0aReBIMpovTKwtoLFrFDEVXG78&usqp=CAU"); */
  background-color: rgba(255, 255, 255, 0.5);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  width: 300px;
}
.login-box:hover {
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Change background color on hover */
}
/* Hover effect for login box */
.login-box:hover {
  background-color: rgba(255, 255, 255, 0.8); /* Change background color */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Increase box shadow */
}

/* Hover effect for social buttons */
.social-button:hover {
  transform: scale(1.05); /* Scale up slightly on hover */
}

/* Hover effect for create account box */
.create-account-box:hover {
  background-color: #45a049; /* Change background color on hover */
}
/* Hover effect for login title */
.login-title:hover {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Add text shadow on hover */
}

/* Hover effect for form link */
.form-link:hover {
  color: #4caf50; /* Change link color on hover */
}

/* Hover effect for signup link */
.signup-link a:hover {
  transform: scale(1.1); /* Scale up text on hover */
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8em;
  color: #4e4c4c;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.form-item {
  margin-bottom: 20px;
}

.form-link {
  float: right;
}

/* Social button styles */
.social-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.social-button {
  flex: 1;
}

/* Create account link styles */
.signup-link {
  text-align: center;
}

.create-account-box {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #4caf50;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #4caf50;
}

.signup-link a {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s;
}

.signup-link a:hover {
  background-color: #45a049;
}

/* Media queries */
@media only screen and (max-width: 768px) {
  /* .container {
    padding: 20px;
  } */

  .login-box {
    width: 100%;
  }

  .login-title {
    font-size: 1.5em;
  }

  .form-item {
    margin-bottom: 10px;
  }
}
