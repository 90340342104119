.centered-card {
    position: absolute;
    top: 92%; /* Position below the parent */
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    width: 100%;

    display: none; /* Initially hide the card */
  }
    
  .head1mr:hover .centered-card {
    display: block; /* Display the card on hover */
  }
  