.useraddress-container {
  min-height: 100vh;
  padding: 20px;
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card1{
position:absolute;
top: 300px;
z-index: 1;
}
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  height: 34px
  }
.ant-select .ant-select-arrow .anticon >svg {
  font-size: 15px
}
.useraddress-content {
  padding: 16px;
  border-radius: 14px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: #C5ADC5;
  
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 0;
  color: #1677ff;
  background: #ffffff;
  border-color: #1677ff;
}

.address-table {
  border-collapse: collapse;
  width: 100%;
 height: 270px;
font-size: 14px;
 line-height: 120%;
 margin: 6px;
 font-family:Arial, Helvetica, sans-serif,

}
.useraddress-header {
  text-align: center;
  font-weight: bold;
  color: #333;
  display: flex;
  justify-content: space-around;
}


.address-table th, .address-table td {
  border: none;
  padding: 10px;
  text-align: left;
}
.address-table td {
  word-wrap: break-word; /* Allow long words to break and wrap */
  max-width: 260px; /* Adjust maximum width as needed */
}
.address-table th {
  background-color: #f0f0f0;
}
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  height: 34px
  }
.ant-select .ant-select-arrow .anticon >svg {
  font-size: 15px
}

.address-table td {
  text-wrap: wrap;
}



.useraddress-card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.4s;
  width: 100%;
  min-height: 227px;
  margin-bottom: 10px;
}

.useraddress-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.useraddress-card .ant-card-actions {
  background-color: #f0f2f5;
  border-top: 1px solid #e8e8e8;
  padding: 8px; /* Adjusted padding */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 54px;
}

.useraddress-card .ant-card-actions > li > div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
  cursor: pointer;
  padding: 8px; /* Adjusted padding */
}

.useraddress-card .ant-card-actions > li > div:hover {
  color: rgba(0, 0, 0, 0.65);
}
.address-text {
  word-wrap: break-word;
  max-width: 300px; /* Set the maximum width here */
  overflow-wrap: break-word;
}

.useraddress-drawer .ant-drawer-body {
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.useraddress-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.useraddress-form .ant-form-item {
  margin-bottom: 16px;
}

.useraddress-form .ant-form-item-label > label {
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
}

.useraddress-form .ant-input {
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 0 11px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.useraddress-form .ant-input:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.useraddress-form .ant-radio-group {
  display: flex;
  align-items: center;
}

.useraddress-form .ant-radio-wrapper {
  margin-right: 16px;
}

.useraddress-form .ant-btn {
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  padding: 0 15px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.useraddress-form .ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
}

.useraddress-form .ant-btn-primary:hover,
.useraddress-form .ant-btn-primary:focus {
  background-color: #40a9ff;
  border-color: #40a9ff;
}

.useraddress-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.useraddress-input:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-color: #aaa;
}

.useraddress-radio-group {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.useraddress-radio-group label {
  margin-right: 10px;
}

.useraddress-drawer {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
}

.useraddress-form .ant-btn {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}
