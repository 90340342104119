/* styles.css */

.header {
  background: black;
  padding: 4px 0px;
  margin: 0;
 height: 112px;
}

.search-textbox {
  margin-left: 35px;
  height: 40px;
  width: 100%;
  border: 1px solid #fff;
  padding-left: 16px;
  border-radius: 10px;
}

.head-container {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.head1mr {
  margin-left: 20px;
}

.head1mr span {
  color: white;
  margin-left: 4px;
}

.wishlist,
.cart {
  margin-right: 10px;
}
