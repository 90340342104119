.fixed-image {
  position: sticky;
  top: 20px; /* Adjust as needed */
}
.cardContainer {
  display: flex;
  justify-content: center;
}

/* Styles for the card itself */
.card {
  width: 60%;
}
/* product.css */

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.card {
  width: 100%;
  padding: 20px;
}

.review {
  margin-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}

.review:last-child {
  border-bottom: none;
}

.review span {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.review p {
  margin: 0;
}
.custom-rate .ant-rate-star {
  font-size: 10px;
}
.ant-rate {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  COLOR: #085d96;
  font-size: 20px;
  line-height: 1;
}