/* General Styles */
.password-assistance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #f8f8f8;
  border-radius: 10px;
  background-image: url('https://tang.com.au/wp-content/uploads/2021/04/2fa.jpeg');
  background-size: cover; /* Ensures the background image covers the entire container */
  background-position: center; /* Centers the background image */
}

.password-assistance-content {
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 280px;
  background-image: url("https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg?w=740&t=st=1715774444~exp=1715775044~hmac=d6a3ab0da0659e1d70aa6307a2729856c5d23462bd056a54e5c26d63ae7066c4");
  background-size: cover; /* Ensures the background image covers the entire container */
  background-position: center; /* Centers the background image */
}

.instructions h6 {
  font-size: 14px;
  line-height: 20px;
}

.input-field {
  width: 100%;
  height: 40px;
}

.submit-button {
  width: 100%;
  border-radius: 20px;
}

.forget-password-image {
  display: block;
  margin: 0 auto; /* Centers the image horizontally */
  height: 150px;
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 768px) {
  .password-assistance-content {
    padding: 20px;
  }

  .input-field {
    height: 35px;
  }

  .submit-button {
    font-size: 14px;
  }
}
.submit-button:hover {
  background-color: #4CAF50; /* Change background color */
  color: white; /* Change text color */
  cursor: pointer; /* Change cursor to pointer */
  transform: scale(1.05); /* Scale up button slightly */
}