/* Default styles */
.anticon {
  font-size: 26px;
}

.background {
  /* height: 70px; */
  width: 100%;
}

.shop-name {
  margin: 0;
}

.location {
  margin: 0;
  margin-left: 10px;
}

.footer {
  text-align: center;
  background: #001529;
  color: #fff;
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-section {
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .footer {
    display: none;
  }
}
